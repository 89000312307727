<template>
	<div class="request">
		<h2>Request Access to Chemformation</h2>
		<p class="text-h5">
			Please fill out the form below to request user access to Chemformation. Once your request is submitted
			we will contact you shortly to set up your account and grant you access to Chemformation.
		</p>

		<v-form ref="form" class="form">
			<div class="form-group-hp">
				<label>Email*</label>
				<v-text-field
					v-model="requestForm.email"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Password*</label>
				<v-text-field
					v-model="requestForm.password"
					:rules="[
						(v) => !!v || 'Required.',
						(v) => (v && v.length >= 6) || 'Password must be at least 6 characters.',
						(v) =>
							(v && /\W/.test(v)) || 'Password must have at least one non alphanumeric character.',
						(v) => (v && /\d/.test(v)) || 'Password must have at least one digit.',
					]"
					required
					single-line
					outlined
					dense
					type="password"
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Re-Enter Password*</label>
				<v-text-field
					v-model="requestForm.confirmPassword"
					:rules="[
						(v) => !!v || 'Required.',
						(v) => (v && v.length >= 6) || 'Password must be at least 6 characters.',
						(v) =>
							(v && /\W/.test(v)) || 'Password must have at least one non alphanumeric character.',
						(v) => (v && /\d/.test(v)) || 'Password must have at least one digit.',
						(v) => (v && v === requestForm.password) || 'Passwords must match.',
					]"
					required
					single-line
					outlined
					dense
					type="password"
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Name*</label>
				<v-text-field
					v-model="requestForm.name"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Company*</label>
				<v-text-field
					v-model="requestForm.company"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Job Title*</label>
				<v-text-field
					v-model="requestForm.jobTitle"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Address 1*</label>
				<v-text-field
					v-model="requestForm.address1"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Address 2</label>
				<v-text-field v-model="requestForm.address2" single-line outlined dense></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Address 3</label>
				<v-text-field v-model="requestForm.address3" single-line outlined dense></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>City*</label>
				<v-text-field
					v-model="requestForm.city"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>State*</label>
				<v-text-field
					v-model="requestForm.state"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Zip Code*</label>
				<v-text-field
					v-model="requestForm.zipCode"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Country</label>
				<v-text-field v-model="requestForm.country" required single-line outlined dense></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Phone*</label>
				<v-text-field
					v-model="requestForm.phone"
					:rules="[(v) => !!v || 'Required.']"
					required
					single-line
					outlined
					dense
				></v-text-field>
			</div>
			<div class="form-group-hp">
				<label>Cell Phone</label>
				<v-text-field v-model="requestForm.cellPhone" single-line outlined dense></v-text-field>
			</div>
			<div class="form-group-hp mt-1">
				<label>&nbsp;</label>
				<v-checkbox
					:rules="[(v) => !!v || 'Required.']"
					required
					name="terms"
					v-model="requestForm.termsAccepted"
				></v-checkbox>
				<a
					style="font-size: 13px; margin-left: 10px"
					v-if="this.isMobile"
					:href="tncLink"
					:download="tncFile"
					target="_blank"
				>
					I accept the Terms and Conditions
				</a>
				<button
					v-else
					type="button"
					style="margin-left: 10px; font-size: 13px"
					class="form-btn-link"
					@click="showTerms = !showTerms"
				>
					I accept the Terms and Conditions
				</button>
			</div>
			<p style="font-size: 13px; float: left; clear: left; margin-top: 1rem">
				* Indicates that the field is required.
			</p>
			<v-btn
				color="#ba0c2f"
				style="float: right; margin-top: 1rem; font-size: 1.25rem; color: white"
				@click="submit()"
			>
				Submit
			</v-btn>
		</v-form>

		<!-- <v-dialog v-if="this.isMobile" v-model="showTerms" fullscreen>
			<v-card style="width: 100%; height: 100%">
				<v-card-title>Terms and Conditions</v-card-title>
				<v-card-text style="height: 100%; width: 100%; position: relative">
					<iframe
						name="innerBrowserFrame"
						style="
							border: 0px;
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							width: 100%;
							height: 100%;
							overflow: auto;
						"
						src="/api/storage/preview-file?filePath=%2FChemformation%20Terms%20and%20Conditions.pdf&dropboxId=id:JLJtptZ5JkUAAAAAAAEonQ"
						scrolling="yes"
					></iframe>
				</v-card-text>
				<v-card-actions style="background-color: #fff; position: fixed; bottom: 0; width: 100%">
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="showTerms = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog> -->
		<v-dialog v-model="showTerms" max-width="1200" max-height="800">
			<v-card>
				<v-card-title>Terms and Conditions</v-card-title>
				<v-card-text>
					<iframe
						name="innerBrowserFrame"
						style="border: 0px; overflow-x: hidden; overflow-y: auto; height: 750px; width: 1150px"
						:src="tncLink"
						scrolling="yes"
					></iframe>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="showTerms = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="formError" max-width="500">
			<v-card>
				<v-card-title>Error</v-card-title>
				<v-card-text style="font-size: 16px; line-height: 25px">
					<ul>
						<li v-for="(error, i) in errors" :key="i">{{ error }}</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="formError = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="showResponseModal" width="500">
			<v-card>
				<v-card-title>Response</v-card-title>
				<v-card-text style="font-size: 16px; line-height: 25px">
					<ul>
						<li v-for="(message, i) in responseMessages" :key="i">{{ message }}</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="routeToLogin">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "RequestAccess",
	data() {
		return {
			requestForm: {
				email: "",
				password: "",
				confirmPassword: "",
				name: "",
				company: "",
				jobTitle: "",
				address1: "",
				address2: "",
				address3: "",
				city: "",
				state: "",
				country: "United States",
				zipCode: "",
				phone: "",
				cellPhone: "",
				termsAccepted: false,
			},
			errors: [],
			formError: false,
			showTerms: false,
			showResponseModal: false,
			responseMessages: [],
			responseSuccess: false,
			isMobile: false,
			tncLink:
				"/api/storage/preview-file?filePath=%2FChemformation%2FBroChem%20Marketing%20Inc%20Price%20Book%2FMiscellaneous%20Products%20-%20use%20to%20start%2FChemformation%20Terms%20and%20Conditions%20Revised%206.7.23.pdf&dropboxId=id:JLJtptZ5JkUAAAAAAAFkwA",
			tncFile: "Chemformation Terms and Conditions",
		};
	},
	methods: {
		async submit() {
			let self = this;
			this.responseMessages = [];

			//validate form
			this.validateForm();

			try {
				if (!this.formError) {
					//send api post request
					const res = await this.$http.post(`/api/account/request`, this.requestForm).then(function () {
						self.responseMessages.push(
							"You have successfully requested access. Your account is pending confirmation. Chemformation will get back with you soon."
						);
						self.responseSuccess = true;
						self.showResponseModal = true;
					});

					console.log(res);
				}
			} catch (error) {
				console.log(error, error.response);
				if (error.response.data) {
					self.responseSuccess = false;
					error.response.data.forEach(function (item) {
						self.responseMessages.push(item);
					});
					this.showResponseModal = true;
				}
			}
		},
		validateForm() {
			this.$refs.form.validate();
			this.errors = [];

			if (!this.requestForm.email) {
				this.errors.push("Email is mandatory");
			}

			if (!this.requestForm.password) {
				this.errors.push("Password is mandatory");
			}

			if (!this.requestForm.confirmPassword) {
				this.errors.push("Re-Enter Password is mandatory");
			}

			if (this.requestForm.password && this.requestForm.confirmPassword) {
				if (this.requestForm.password !== this.requestForm.confirmPassword) {
					this.errors.push("Password fields do not match.");
				}
			}

			if (!this.requestForm.name) {
				this.errors.push("Name is mandatory");
			}

			if (!this.requestForm.company) {
				this.errors.push("Company is mandatory");
			}

			if (!this.requestForm.jobTitle) {
				this.errors.push("Title is mandatory");
			}

			if (!this.requestForm.address1) {
				this.errors.push("Address 1 is mandatory");
			}

			if (!this.requestForm.city) {
				this.errors.push("City is mandatory");
			}

			if (!this.requestForm.state) {
				this.errors.push("State is mandatory");
			}

			if (!this.requestForm.zipCode) {
				this.errors.push("Zip Code is mandatory");
			}

			if (!this.requestForm.phone) {
				this.errors.push("Phone is mandatory");
			}

			if (!this.requestForm.termsAccepted) {
				this.errors.push("Terms must be checked");
			}

			if (this.errors.length > 0) {
				this.formError = true;
			}
		},
		routeToLogin() {
			this.showResponseModal = false;
			if (this.responseSuccess) {
				this.$router.push("/login");
			}
		},
	},
	mounted() {
		this.isMobile = this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
	},
};
</script>

<style lang="scss" scoped>
.request {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 25px;

	h2 {
		margin: 1.5rem 0 1rem;
	}
}
</style>
